import {
    OPTED_IN,
    OPT_IN_ERROR,
    OPT_IN_REQUEST,
    TOKEN_SUCCESS,
    TOKEN_ERROR
} from "../actions/types";

const initialState = { loading: false, optedIn: false };

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case OPT_IN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case OPTED_IN:
        case TOKEN_SUCCESS:
            localStorage.setItem("skuppity", payload.token);
            return {
                ...state,
                loading: false,
                optedIn: true
            };
        case OPT_IN_ERROR:
        case TOKEN_ERROR:
            localStorage.removeItem("skuppity");
            return { ...state, loading: false, optedIn: false };
        default:
            return { ...state, loading: false };
    }
}
