import axios from "axios";
import {
    OPTED_IN,
    OPT_IN_ERROR,
    OPT_IN_REQUEST,
    TOKEN_SUCCESS,
    TOKEN_ERROR,
} from "./types";

export const checkToken = () => async (dispatch) => {
    try {
        const token = localStorage.getItem("skuppity");
        if (token) {
            const head = {
                headers: {
                    "Content-Type": "application/json",
                    skuppity: localStorage.getItem("skuppity"),
                },
            };
            const res = await axios.post("/email/token", null, head);
            dispatch({ type: TOKEN_SUCCESS, payload: res.data });
        } else {
            dispatch({ type: TOKEN_ERROR, payload: "No Token Present" });
        }
    } catch (err) {
        dispatch({ type: TOKEN_ERROR, payload: err.message });
    }
};

export const createContact = (email) => async (dispatch) => {
    try {
        dispatch({
            type: OPT_IN_REQUEST,
            payload: null,
        });
        const head = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const body = JSON.stringify({ email });
        const res = await axios.post("/email", body, head);

        dispatch({
            type: OPTED_IN,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: OPT_IN_ERROR,
            payload: err.message,
        });
    }
};
