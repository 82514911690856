import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Calculator from "../calculator/Calculator";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { checkToken } from "../../actions/email";
import "../../css/landing.css";

const Landing = ({ checkToken }) => {
    const [browser, setBrowser] = useState(null);

    useEffect(() => {
        if (window.sidebar && window.sidebar.addPanel) {
            setBrowser("firefox");
        } else if (window.external && "AddFavorite" in window.external) {
            setBrowser("ie");
        } else if (window.opera && window.print) {
            setBrowser("opera");
        } else {
            navigator.userAgent.toLowerCase().indexOf("mac") !== -1
                ? setBrowser("safari")
                : setBrowser("chrome");
        }

        checkToken();
    }, [setBrowser, checkToken]);

    const bookmark = () => {
        switch (browser) {
            case "opera":
                return <strong>Press Ctrl + D to bookmark this page</strong>;
            case "safari":
                return (
                    <strong>Press Ctrl/Cmd + D to bookmark this page</strong>
                );
            case "chrome":
                return (
                    <strong>Press Ctrl/Cmd + D to bookmark this page</strong>
                );
            default:
                return (
                    <strong>Press Ctrl/Cmd + D to bookmark this page</strong>
                );
        }
    };

    return (
        <div className="calculator-parent">
            <div className="bg-rect-1"></div>
            <div className="bg-rect-2"></div>
            <div className="bg-rect-3"></div>
            <div className="bg-rect-4"></div>
            <div className="bg-rect-5"></div>
            <div className="bg-rect-6"></div>
            <div className="bg-rect-7"></div>
            <div className="bg-rect-8"></div>
            <Row>
                <Col align="center">
                    <h1 className="roi-calc-title">
                        Free Facebook Ads Cost ROI Calculator
                    </h1>
                    <p className="roi-desc">
                        Calculate the costs of your Facebook ads with our free
                        ROI Calulator to know your target numbers. {bookmark()}
                    </p>
                </Col>
            </Row>
            <Calculator />
        </div>
    );
};

Landing.propTypes = {
    checkToken: PropTypes.func.isRequired
};

export default connect(null, { checkToken })(Landing);
