import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    Col,
    Row,
    FormGroup,
    // FormFeedback,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
} from "reactstrap";
import { Slider } from "@material-ui/core";
import EmailCollect from "../email/EmailCollect";
import "./calculator.css";

const Calculator = ({ email: { loading, optedIn } }) => {
    const [formData, setFormData] = useState({
        salePrice: 0.0,
        saleProfit: 0.0,
        evenROA: 0.0,
        avgConvRate: 5,
        customCPLC: 0.0,
        profitMargin: 0.0,
    });
    const {
        salePrice,
        saleProfit,
        // evenROA,
        avgConvRate,
        customCPLC,
        profitMargin,
    } = formData;

    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:
                e.target.value.length > 0 ? parseFloat(e.target.value) : 0.0,
            evenROA: (salePrice && salePrice !== 0.0
                ? salePrice / saleProfit
                : 0.0
            ).valueOf(),
        });
    };

    const onSliderChange = (v) => {
        setFormData({ ...formData, avgConvRate: v });
    };

    const valueText = (value) => {
        return `${value}%`;
    };

    const marks = [
        {
            value: 0,
            label: "0%",
        },
        {
            value: 100,
            label: "100%",
        },
    ];

    const costPerLinkClick = () => {
        return parseFloat(
            ((parseFloat(avgConvRate) * parseFloat(saleProfit)) / 100).toFixed(
                2
            )
        );
    };

    /* ADVERTISING COST => CPLC / Conversion Rate */
    const costPerPurchase = (clickCost) => {
        let cplc = parseFloat(clickCost) || costPerLinkClick();
        return parseFloat((cplc / parseFloat(avgConvRate)) * 100);
    };

    const costOfProduct = () => {
        return parseFloat(salePrice) - parseFloat(saleProfit);
    };

    const costOfGoodsSold = (customCPLC) => {
        let cplc = customCPLC ? customCPLC : null;
        return costOfProduct() + costPerPurchase(cplc);
    };

    const calculateROA = () => {
        return (
            salePrice && salePrice !== 0.0
                ? parseFloat(salePrice) / parseFloat(saleProfit)
                : 0.0
        ).toFixed(2);
    };

    const calculateROI = () => {
        let ROI =
            avgConvRate <= 0
                ? 0.0
                : (
                      1 +
                      (parseFloat(salePrice) - costOfGoodsSold(customCPLC)) /
                          costOfGoodsSold(customCPLC)
                  ).toFixed(2);
        return ROI;
    };

    const calculateProfitMargin = () => {
        return (
            ((parseFloat(salePrice) - costOfGoodsSold(customCPLC)) /
                parseFloat(salePrice)) *
            100
        );
    };

    const calculateSalePlusProfitMargin = (profitMargin) => {
        // calculate AOV (sale price) given COGS (break-even) + profit %
        return (
            parseFloat(costOfGoodsSold(customCPLC)) *
            (1 +
                parseFloat(profitMargin) /
                    100 /
                    (1 - parseFloat(profitMargin) / 100))
        );
    };

    // AOV IS THE SALE PRICE
    const minimumAOV = (profitMargin, useCustomCPLC = false) => {
        if (profitMargin) {
            return calculateSalePlusProfitMargin(profitMargin);
        } else
            return (
                // Minimum AOV with 0% profit margin (break-even) = COGS
                useCustomCPLC ? costOfGoodsSold(customCPLC) : costOfGoodsSold()
            );
    };

    return (
        <div className="calculator col-sm-12 col-md-12" align-contents="center">
            <Row xl={12}>
                <span className="legend">
                    Abbreviations: (AOV) Average Order Value, (COGS) Cost of
                    goods sold, (CPLC) Cost per link click, (CPP) Cost per
                    purchase.
                </span>
            </Row>
            <Col
                // xs={{ size: 10, offset: 1 }}
                sm={{ size: 12 }}
                md={9}
                align="center"
                className="calculator-inner"
                // justifyContents="center"
                style={{ justifyContent: "center" }}
            >
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="salePrice">
                                What's Your Product's Price?
                            </Label>
                            <InputGroup>
                                <Input
                                    type="text"
                                    name="salePrice"
                                    value={salePrice}
                                    onChange={(e) => onChange(e)}
                                    id="salePrice"
                                    placeholder="10.00"
                                    style={{ paddingLeft: "30px" }}
                                    required
                                />
                                <span
                                    className="symbol"
                                    style={{
                                        position: "absolute",
                                        left: "0px",
                                        top: "6px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    $
                                </span>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="productPrice">
                                What's Your Profit Per Item?
                            </Label>
                            <InputGroup>
                                <Input
                                    type="text"
                                    name="saleProfit"
                                    value={saleProfit}
                                    onChange={(e) => onChange(e)}
                                    id="saleProfit"
                                    placeholder="5.00"
                                    style={{ paddingLeft: "30px" }}
                                    required
                                />
                                <span
                                    className="symbol"
                                    style={{
                                        position: "absolute",
                                        left: "0px",
                                        top: "6px",
                                        paddingLeft: "15px",
                                    }}
                                >
                                    $
                                </span>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6}>
                        <FormGroup>
                            <Label for="breakevenROA">
                                Your Breakeven ROA Is
                            </Label>
                            <InputGroup>
                                <Input
                                    type="text"
                                    className="read-only-input"
                                    disabled
                                    name="breakevenROA"
                                    value={calculateROA()}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                        <FormGroup>
                            <Label for="conversionSlider">
                                What's Your Average Conversion Rate?
                            </Label>
                            <InputGroup>
                                <Slider
                                    defaultValue={avgConvRate}
                                    getAriaValueText={valueText}
                                    name="avgConvRate"
                                    onChangeCommitted={(e, v) =>
                                        onSliderChange(v)
                                    }
                                    valueLabelDisplay="auto"
                                    marks={marks}
                                ></Slider>
                            </InputGroup>
                            {avgConvRate && avgConvRate > 30 && (
                                <div
                                    style={{ display: "table", margin: "auto" }}
                                >
                                    {`${avgConvRate}%`}, sure... sure{" "}
                                    <span role="img" aria-label="lying face">
                                        🤥
                                    </span>
                                </div>
                            )}
                        </FormGroup>
                    </Col>
                </Row>

                {!optedIn && (
                    <div className="row email-collect-parent">
                        <EmailCollect />
                    </div>
                )}

                {optedIn && (
                    <div className="extendedCalculator">
                        <Row>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label for="costPerLinkClick">
                                        To Break Even, CPLC must be under &nbsp;
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            className="read-only-input"
                                            disabled
                                            name="costPerLinkClick"
                                            style={{ paddingLeft: "30px" }}
                                            type="text"
                                            value={costPerLinkClick().toFixed(
                                                2
                                            )}
                                        />
                                        <span
                                            className="symbol"
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                top: "6px",
                                                paddingLeft: "15px",
                                            }}
                                        >
                                            $
                                        </span>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label for="costPerPurchase">
                                        To Break Even with a CPLC of{" "}
                                        {`$${costPerLinkClick()}`}, CPP must be
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            className="read-only-input"
                                            disabled
                                            name="costPerPurchase"
                                            style={{ paddingLeft: "30px" }}
                                            type="text"
                                            value={costPerPurchase().toFixed(2)}
                                        />
                                        <span
                                            className="symbol"
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                top: "6px",
                                                paddingLeft: "15px",
                                            }}
                                        >
                                            $
                                        </span>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <h3 className="extended-header">Let's Go Deeper</h3>
                        <Row>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label for="customCPLC">
                                        What's your average CPLC?
                                    </Label>
                                    <InputGroup>
                                        {/* <InputGroupAddon addonType="prepend">
                                            $
                                        </InputGroupAddon> */}

                                        <Input
                                            type="number"
                                            name="customCPLC"
                                            id="customCPLC"
                                            step="0.01"
                                            style={{ paddingLeft: "30px" }}
                                            onChange={(e) => onChange(e)}
                                            pattern="/^\d*(\.)?\d{0,2}$"
                                            onKeyUp={(e) => {
                                                if (
                                                    e.target.value.length >=
                                                        1 &&
                                                    e.target.value[0] === "."
                                                )
                                                    e.target.value =
                                                        "0" + e.target.value;
                                            }}
                                        />
                                        <span
                                            className="symbol"
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                top: "6px",
                                                paddingLeft: "15px",
                                                zIndex: "4",
                                            }}
                                        >
                                            $
                                        </span>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="overallROI">
                                        This CPP will result in an{" "}
                                        <strong>Overall ROI</strong> of
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            className="read-only-input"
                                            type="text"
                                            style={
                                                calculateROI() >= 1
                                                    ? {
                                                          color: "green",
                                                          fontWeight: "bold",
                                                      }
                                                    : {
                                                          color: "red",
                                                          fontWeight: "bold",
                                                      }
                                            }
                                            disabled
                                            value={calculateROI()}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label for="customCPP">
                                        {/* With Your Given CPLC of{" "}
                                        <strong>{`$${customCPLC || 0}`}</strong>
                                        ,  */}
                                        Your More Accurate CPP is:{" "}
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            className="read-only-input"
                                            disabled
                                            name="customCPP"
                                            style={{ paddingLeft: "30px" }}
                                            type="text"
                                            value={costPerPurchase(
                                                customCPLC
                                            ).toFixed(2)}
                                        />
                                        <span
                                            className="symbol"
                                            style={{
                                                position: "absolute",
                                                left: "0px",
                                                top: "6px",
                                                paddingLeft: "15px",
                                            }}
                                        >
                                            $
                                        </span>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                {avgConvRate > 0 && calculateROI() < 1 && (
                                    <div className="aov-projection-result aov-projection-negative">
                                        Oh No!{" "}
                                        <span
                                            role="img"
                                            aria-label="disappointed face"
                                        >
                                            😞
                                        </span>
                                        Your ROI is below 1! To get break even
                                        ROI, you'll need to boost your AOV by $
                                        {(
                                            minimumAOV(null, true) -
                                            parseFloat(salePrice)
                                        ).toFixed(2)}
                                        , to
                                        <div className="required-aov">
                                            ${minimumAOV(null, true).toFixed(2)}
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>

                        {avgConvRate <= 0 && (
                            <p>
                                To learn more, you'll need a Conversion Rate
                                above 0%{" "}
                                <span role="img" aria-label="winking face">
                                    😉
                                </span>
                            </p>
                        )}

                        {/* {avgConvRate > 0 && (
                            <p>
                                Your <strong>Current Profit Margin</strong> is{" "}
                                <strong>
                                    {calculateProfitMargin().toFixed(2)}%
                                </strong>
                            </p>
                        )} */}

                        <Fragment>
                            <hr />
                            <Row>
                                <Col xs={12} sm={6}>
                                    <FormGroup>
                                        <Label for="profitMargin">
                                            What's your desired Profit Margin
                                            (%)?
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                id="profitMargin"
                                                name="profitMargin"
                                                type="number"
                                                pattern="/^\d{0,2}(\.)?\d{0,2}$"
                                                step="0.01"
                                                style={{ margin: "auto 0" }}
                                                onChange={(e) => onChange(e)}
                                                defaultValue={0}
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.target.value.length >
                                                            1 &&
                                                        e.target.value[0] ===
                                                            "0"
                                                    )
                                                        e.target.value =
                                                            e.target.value.substr(
                                                                1
                                                            );
                                                    else if (
                                                        e.target.value.length <
                                                        1
                                                    )
                                                        e.target.value = "0";
                                                }}
                                            ></Input>
                                            <span
                                                className="symbol"
                                                style={{
                                                    position: "absolute",
                                                    right: "0px",
                                                    top: "6px",
                                                    paddingRight: "30px",
                                                }}
                                            >
                                                %
                                            </span>
                                            {/* <InputGroupAddon addonType="append">
                                            %
                                        </InputGroupAddon> */}
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} sm={6}>
                                    {avgConvRate > 0 && (
                                        <div className="aov-projection-result aov-projection-positive">
                                            To hit that profit margin of{" "}
                                            <strong>{profitMargin}%</strong>{" "}
                                            with a CPLC of{" "}
                                            {customCPLC
                                                ? `$${customCPLC}`
                                                : "$0.00"}{" "}
                                            and COGS of $
                                            {costOfGoodsSold(
                                                customCPLC
                                            ).toFixed(2)}
                                            , you'd need an AOV of{" "}
                                            <div className="required-aov">
                                                $
                                                {(profitMargin
                                                    ? minimumAOV(
                                                          profitMargin,
                                                          true
                                                      )
                                                    : minimumAOV(null, true)
                                                ).toFixed(2)}
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    </div>
                )}
            </Col>

            <Row xl={12}>
                <span className="legend">
                    Abbreviations: (AOV) Average Order Value, (COGS) Cost of
                    goods sold, (CPLC) Cost per link click, (CPP) Cost per
                    purchase.
                </span>
            </Row>
        </div>
    );
};

const mapStateToProps = (state) => ({
    email: state.email,
});

export default connect(mapStateToProps, null)(Calculator);
