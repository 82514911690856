import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import "./emailCollect.css";

import { createContact } from "../../actions/email";

const EmailCollect = ({ createContact, email: { loading, optedIn } }) => {
    const [email, setEmail] = useState({
        email: "",
    });
    const [emailError, setEmailError] = useState(false);

    const ValidateEmail = (email) => {
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    };

    const onChange = (e) => {
        let isValid = ValidateEmail(e.target.value);
        if (isValid) {
            setEmailError(false);
            setEmail(e.target.value);
        } else {
            setEmailError(true);
            setEmail(e.target.value);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        await createContact(email);
    };

    return (
        <Col className="emailCollect" sm={12}>
            <Row className="markdown">
                <Col className="markdown-left" xs={12} md={6}>
                    <div className="md-elements md-label-1" />
                    <div className="md-elements md-label-2" />
                    <div className="md-elements md-label-3" />
                    <div className="md-elements md-label-4" />
                    <div className="md-elements md-input-1" />
                    <div className="md-elements md-input-2" />
                    <div className="md-elements md-input-3" />
                    <div className="md-elements md-input-4" />
                </Col>
                <Col className="markdown-right" xs={12} md={6}>
                    <div className="md-elements md-label-5" />
                    <div className="md-elements md-label-6" />
                    <div className="md-elements md-label-7" />
                    <div className="md-elements md-label-8" />
                    <div className="md-elements md-input-5" />
                    <div className="md-elements md-input-6" />
                    <div className="md-elements md-input-7" />
                    <div className="md-elements md-input-8" />
                </Col>
            </Row>
            <Form onSubmit={(e) => onSubmit(e)} className="emailForm">
                <FormGroup>
                    <Label for="emailInput">
                        Enter Your Email to Reveal Your Profitable Numbers!
                    </Label>
                    <InputGroup>
                        <Input
                            type="email"
                            name="email"
                            id="emailInput"
                            className={
                                emailError
                                    ? "emailInput is-invalid"
                                    : "emailInput is-valid"
                            }
                            placeholder="your@email.com"
                            onChange={(e) => onChange(e)}
                            // style={{ border: emailError ? "3px solid yellow" }}
                        />
                    </InputGroup>
                </FormGroup>
                <Button
                    className="emailSubmit btn-success"
                    id="emailSubmitBtn"
                    type="submit"
                    disabled={loading || !ValidateEmail(email)}
                >
                    {loading && (
                        <Fragment>
                            <Spinner
                                style={{ width: "1rem", height: "1rem" }}
                            />{" "}
                            <span>Calculating</span>
                        </Fragment>
                    )}
                    {!loading && (
                        <span visible={loading ? "false" : "true"}>
                            Calculate
                        </span>
                    )}
                </Button>
            </Form>
        </Col>
    );
};

EmailCollect.propTypes = {
    createContact: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    email: state.email,
});

export default connect(mapStateToProps, { createContact })(EmailCollect);
