import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { Container, Navbar, NavbarBrand } from "reactstrap";
import store from "./store";
import dotenv from "dotenv";
import "./App.css";
import Landing from "./components/layout/Landing";

const App = () => {
    dotenv.config();

    return (
        <Provider store={store}>
            <Router>
                <Fragment>
                    <Container>
                        <div className="App">
                            <Navbar className="main-navbar">
                                <NavbarBrand href="/">
                                    <img
                                        src={require("./assets/logo@2x.png")}
                                        className="header-logo"
                                        alt="SKUP"
                                    />
                                </NavbarBrand>
                                <nav className="navbar navbar-expand-md">
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#navbarNav"
                                        aria-controls="navbarNav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div
                                        className="pull-right collapse navbar-collapse"
                                        id="navbarNav"
                                    >
                                        <div className="desktop-nav">
                                            <a href="https://skup.net/free-trial/">
                                                Products
                                            </a>
                                            <a href="https://skup.net/about/">
                                                Our Team
                                            </a>
                                            <a href="https://skup.net/blog/">
                                                Blog
                                            </a>
                                            <a
                                                href="https://skup.net/support/"
                                                className="support-btn"
                                            >
                                                Support
                                            </a>
                                        </div>
                                        <div className="mobile-nav">
                                            <button
                                                className="navbar-toggler"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target="#navbarNav"
                                                aria-controls="navbarNav"
                                                aria-expanded="false"
                                                aria-label="Toggle navigation"
                                            >
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                            <a
                                                data-toggle="collapse"
                                                href="#mobile-collapse-1"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="mobile-collapse-1"
                                            >
                                                Products
                                            </a>
                                            <div
                                                className="collapse"
                                                id="mobile-collapse-1"
                                            >
                                                <a
                                                    href="https://apps.shopify.com/smar7-express"
                                                    className="one-footer-link espress-link"
                                                >
                                                    Express
                                                </a>
                                                <a
                                                    href="https://apps.shopify.com/smar7-bundle-upsell"
                                                    className="one-footer-link bundle-link"
                                                >
                                                    Bundle
                                                </a>
                                                <a
                                                    href="https://apps.shopify.com/smar7-scarcity-timer"
                                                    className="one-footer-link scarcity-link"
                                                >
                                                    Scarcity
                                                </a>
                                                <a
                                                    href="https://skup.net/cookie"
                                                    className="one-footer-link cookie-link"
                                                >
                                                    Cookie
                                                </a>
                                                <a
                                                    href="https://themes.shopify.com/themes/providence/styles/seaside"
                                                    className="one-footer-link providence-link"
                                                >
                                                    Providence
                                                </a>
                                            </div>
                                            <a href="https://skup.net/about/">
                                                Our Team
                                            </a>
                                            <a href="https://skup.net/blog/">
                                                Blog
                                            </a>
                                            <a
                                                href="https://skup.net/support/"
                                                className="support-btn"
                                            >
                                                Support
                                            </a>
                                        </div>
                                    </div>
                                </nav>
                            </Navbar>
                            <Route exact path={"/"} component={Landing} />
                            <Switch>
                                {/* <Route exact path="/email" /> */}
                            </Switch>
                        </div>
                    </Container>
                    <footer className="main-footer">
                        <Container>
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="skup-footer-logo"></div>
                                    <div className="skup-footer-title">
                                        Everything you need to start, grow and
                                        scale your e-commerce business
                                    </div>
                                    <div className="skup-footer-desc">
                                        We offer tools and training that can
                                        transform your $100k Shopify store into
                                        a multimillion-dollar business.
                                    </div>
                                    <div className="skup-footer-copy">
                                        &copy; Skup. All rights reserved.
                                        <br />
                                        689 Central Ave. Suite 100-J, Saint
                                        Petersburg, Florida, Fl 33701
                                    </div>
                                    <div className="skup-footer-links">
                                        <a href="https://skup.net/privacy-policy/">
                                            Privacy Policy
                                        </a>{" "}
                                        –{" "}
                                        <a href="https://skup.net/terms-of-use/">
                                            Terms of Use
                                        </a>{" "}
                                        –{" "}
                                        <a href="https://skup.net/disclaimer/">
                                            Disclaimer
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-6 offset-lg-1">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="footer-right-title">
                                                Products
                                            </div>
                                            <a
                                                href="https://apps.shopify.com/smar7-express"
                                                className="one-footer-link espress-link"
                                            >
                                                Express
                                            </a>
                                            <a
                                                href="https://apps.shopify.com/smar7-bundle-upsell"
                                                className="one-footer-link bundle-link"
                                            >
                                                Bundle
                                            </a>
                                            <a
                                                href="https://apps.shopify.com/smar7-scarcity-timer"
                                                className="one-footer-link scarcity-link"
                                            >
                                                Scarcity
                                            </a>
                                            <a
                                                href="https://skup.net/cookie"
                                                className="one-footer-link cookie-link"
                                            >
                                                Cookie
                                            </a>
                                            <a
                                                href="https://themes.shopify.com/themes/providence/styles/seaside"
                                                className="one-footer-link providence-link"
                                            >
                                                Providence
                                            </a>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="footer-right-title">
                                                Company
                                            </div>
                                            <a
                                                href="https://skup.net/about/"
                                                className="one-footer-link"
                                            >
                                                Our Team
                                            </a>
                                            <a
                                                href="https://skup.net/blog/"
                                                className="one-footer-link"
                                            >
                                                Blog
                                            </a>
                                            <a
                                                href="https://skup.net/support/"
                                                className="one-footer-link"
                                            >
                                                Support
                                            </a>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="footer-right-title">
                                                Social Media
                                            </div>
                                            <a
                                                href="https://www.facebook.com/SkupEcommerce/"
                                                className="one-footer-link facebook-link"
                                            >
                                                Facebook
                                            </a>
                                            <a
                                                href="https://www.instagram.com/skup_ecommerce/"
                                                className="one-footer-link instagram-link"
                                            >
                                                Instagram
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </footer>
                </Fragment>
            </Router>
        </Provider>
    );
};

export default App;
